import axios from "./http/request"
//请求示例
//get
// export const mokeGet = (data) => {
//     return axios({
//         url: "/api/xxxx",
//         method: "get",
//         data,
//         config: {
//             headers: {
//                 'Request-Type': 'wechat',
//                 '登录口令': '登录口令',
                
//             },
//             timeout: 10000
//         }
//     })
// }
//post
// export const mokePost = (data) => {
//     return axios({
//         url: "/api/xxxx",
//         method: "post",
//         data,
//         config: {
//             headers: {
//                 'Request-Type': 'wechat'
//             },
//             timeout: 10000
//         }
//     })
// }

export const get_host = (data) => {
    return axios({
        url: "/api/index/get_host",
        method: "post",
        data
    })
}

export const dao_hang_list = (data) => {
    return axios({
        url: "/api/index/dao_hang_list",
        method: "post",
        data
    })
}

export const dao_hang_type = (data) => {
    return axios({
        url: "/api/index/dao_hang_type",
        method: "post",
        data
    })
}

export const update_search_tips = (data) => {
    return axios({
        url: "/api/index/update_search_tips",
        method: "post",
        data
    })
}

export const search_tips_list = (data) => {
    return axios({
        url: "/api/index/search_tips_list",
        method: "post",
        data
    })
}

export const get_dao_hang_info = (data) => {
    return axios({
        url: "/api/index/get_dao_hang_info",
        method: "post",
        data
    })
}

export const edit_dao_hang = (data) => {
    return axios({
        url: "/api/index/edit_dao_hang",
        method: "post",
        data
    })
}

export const del_dao_hang = (data) => {
    return axios({
        url: "/api/index/del_dao_hang",
        method: "post",
        data
    })
}

export const get_dao_hang_type_info = (data) => {
    return axios({
        url: "/api/index/get_dao_hang_type_info",
        method: "post",
        data
    })
}

export const edit_dao_hang_type = (data) => {
    return axios({
        url: "/api/index/edit_dao_hang_type",
        method: "post",
        data
    })
}

export const del_dao_hang_type = (data) => {
    return axios({
        url: "/api/index/del_dao_hang_type",
        method: "post",
        data
    })
}

export const add_dao_hang_type = (data) => {
    return axios({
        url: "/api/index/add_dao_hang_type",
        method: "post",
        data
    })
}

export const add_dao_hang = (data) => {
    return axios({
        url: "/api/index/add_dao_hang",
        method: "post",
        data
    })
}

export const login = (data) => {
    return axios({
        url: "/api/index/login",
        method: "post",
        data
    })
}

export const check_token = (data) => {
    return axios({
        url: "/api/index/check_token",
        method: "post",
        data
    })
}

export const exit_login = (data) => {
    return axios({
        url: "/api/index/exit_login",
        method: "post",
        data
    })
}

export const article_list = (data) => {
    return axios({
        url: "/api/index/article_list",
        method: "post",
        data
    })
}

export const article_type = (data) => {
    return axios({
        url: "/api/index/article_type",
        method: "post",
        data
    })
}

export const get_article_info = (data) => {
    return axios({
        url: "/api/index/get_article_info",
        method: "post",
        data
    })
}

export const add_article = (data) => {
    return axios({
        url: "/api/index/add_article",
        method: "post",
        data
    })
}

export const edit_article = (data) => {
    return axios({
        url: "/api/index/edit_article",
        method: "post",
        data
    })
}

export const get_wode_yingyong = (data) => {
    return axios({
        url: "/api/index/get_wode_yingyong",
        method: "post",
        data
    })
}

export const text_to_arr = (data) => {
    return axios({
        url: "/api/tool/text_to_arr",
        method: "post",
        data
    })
}

export const text_to_base64_decode = (data) => {
    return axios({
        url: "/api/tool/text_to_base64_decode",
        method: "post",
        data
    })
}

export const text_to_base64_encode = (data) => {
    return axios({
        url: "/api/tool/text_to_base64_encode",
        method: "post",
        data
    })
}