<template>
	<el-container class="layout-container-demo" style="height: 100%; border: 1px solid #eee">

		<el-aside class="aside" v-if="显示菜单">
			<el-scrollbar>
				<div class="f-24 c-white"
					style="line-height: 50px;height: 50px;background-color: #145ccd;border-bottom: 1px solid #dcdcdc;">
					PHP技术解析
				</div>

				<!-- <el-menu active-text-color="#ffd04b" background-color="#145ccd" text-color="#ffffff">
        <el-sub-menu v-for="(v,k) in 文章类型" :key="'文章类型'+k" :index="String(k)" style="border-bottom: 1px solid #818181;">
          <template #title>
            {{v}}
          </template>

          <el-menu-item v-for="(val,key) in 文章列表[v]" :key="key" :index="String(val.文章编号)" @click="获取文章详情(val.文章编号)"
            style="border-top: 1px solid #818181;" :title="val.文章标题">{{val.文章标题}}</el-menu-item>
        </el-sub-menu>

      </el-menu> -->

				<el-tree :data="文章列表" @node-click="handleNodeClick" />
			</el-scrollbar>
		</el-aside>

		<el-container>
			<el-header>
				<div class="cl no-select">
					<div v-if="显示菜单" class="f-l f-18 首页标题 pl-10 pr-10 width-40" @click="显示菜单=false;">隐藏</div>
					<div v-else class="f-l f-18 首页标题 pl-10 pr-10 width-40" @click="显示菜单=true;">显示</div>
					<div v-if="用户信息" class="f-l f-18 首页标题 pl-10 pr-10 width-40" @click="打开管理()">管理</div>
					<div v-if="用户信息" class="f-l f-18 首页标题 pl-10 pr-10 width-80" @click="发布文章()">发布文章</div>
					<div class="f-l f-18 ml-10">
						<el-input v-model="搜索关键词" class="width-300" placeholder="请输入搜索关键词..." :prefix-icon="Search"
							@input="获取文章列表()" clearable />
					</div>

					<template v-if="用户信息">
						<div class="f-r f-18 首页标题 pl-10 pr-10 width-40" @click="退出()">退出</div>
						<div v-if="用户信息" class="f-r f-18 首页标题 pl-10 pr-10" style="height: 50px;">
							<el-avatar class="mt-5" :size="40" :src="服务器网址+用户信息.头像"></el-avatar>
							<div class="f-r f-18 首页标题 pl-10 pr-10">{{用户信息.用户名}}</div>
						</div>

					</template>

					<template v-else>
						<div class="f-r f-18 首页标题 pl-10 pr-10 width-40" @click="打开登录()">登录</div>
					</template>



				</div>
			</el-header>

			<el-main class="main">
				<div v-if="文章详情.文章编号" class="ml-10 mr-10 mt-10 mb-10">
					<div class="文章标题">{{文章详情.文章标题}}</div>

					<div class="文章描述 mt-10">
						<div class="f-14 pa-10" style="border-bottom: 1px solid #cfcfcf;">
							<span class="f-b">发布时间：</span><span>{{文章详情.发布时间}}</span>
							<el-button v-if="用户信息" class="ml-5 f-16" type="primary" @click="编辑文章(文章详情.文章编号)">编辑文章
							</el-button>
							<div class="f-r">
								<el-tag type="success" effect="dark" size="small">{{文章详情.文章分类}}</el-tag>
								<el-tag class="ml-10" type="danger" effect="dark" size="small">👁️ {{文章详情.推荐数}}</el-tag>
							</div>
						</div>
						<div class="f-14 pa-10">
							<span class="f-b">摘要：</span><span>{{文章详情.文章摘要}}</span>
						</div>
					</div>

					<div class="mt-10" v-html="文章详情.文章内容"></div>

					<div class="f-14 mt-20 pa-10" style="border-top: 1px dashed #9e9e9e;">
						<span class="c-666">本文由作者发布，如有侵权，请联系本站删除！</span>
						<span class="f-r c-orange">联系本站</span>
					</div>
				</div>
				<div v-else>
					<div class="text-c mt-50 f-30">欢迎使用,请点击右边菜单查询资料</div>
				</div>
			</el-main>

			<el-footer>
				<div class="text-c f-20 c-white">Copyright(c){{当前年份}} <a href="https://beian.miit.gov.cn/"
						target="_blank">网站备案号：粤ICP备2020136594号</a>
				</div>
			</el-footer>
		</el-container>

	</el-container>

	<div>
		<el-dialog v-if="显示发布文章" title="发布文章" v-model="显示发布文章" width="900px" top="50px">
			<div>
				<div class="ml-20 mr-20">
					<el-form :rules="发布文章表单规则" ref="发布文章表单编号" :model="发布文章表单" label-width="auto">
						<el-form-item label="文章标题" prop="文章标题">
							<el-input placeholder="请输入文章标题" v-model="发布文章表单.文章标题"></el-input>
						</el-form-item>

						<el-form-item label="文章分类" prop="文章分类">
							<el-select style="width: 100%" v-model="发布文章表单.文章分类" placeholder="请选择">
								<el-option v-for="v in 文章分类" :key="'发布文章分类'+v.分类" :label="v.分类" :value="v.分类">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="文章摘要" prop="文章摘要">
							<el-input type="textarea" placeholder="请输入文章摘要" v-model="发布文章表单.文章摘要"></el-input>
						</el-form-item>

						<el-form-item label="文章内容" prop="文章内容">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editorId="编辑器一号" />
								<Editor style="height: 350px;" :editorId="编辑器一号" :defaultConfig="editorConfig" />
							</div>
						</el-form-item>

					</el-form>
				</div>
				<div class="text-c">
					<el-button type="primary" @click="提交发布文章表单(发布文章表单编号)" :loading="发布文章中">提交</el-button>
				</div>

			</div>
		</el-dialog>


		<el-dialog v-if="显示编辑文章" title="编辑文章" v-model="显示编辑文章" width="900px" top="50px">
			<div>
				<div class="ml-20 mr-20">
					<el-form :rules="编辑文章表单规则" ref="编辑文章表单编号" :model="编辑文章表单" label-width="auto">
						<el-form-item label="文章标题" prop="文章标题">
							<el-input placeholder="请输入文章标题" v-model="编辑文章表单.文章标题"></el-input>
						</el-form-item>

						<el-form-item label="文章分类" prop="文章分类">
							<el-select style="width: 100%" v-model="编辑文章表单.文章分类" placeholder="请选择">
								<el-option v-for="v in 文章分类" :key="'编辑文章分类'+v.分类" :label="v.分类" :value="v.分类">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="文章摘要" prop="文章摘要">
							<el-input type="textarea" placeholder="请输入文章摘要" v-model="编辑文章表单.文章摘要"></el-input>
						</el-form-item>

						<el-form-item label="文章内容" prop="文章内容">
							<div style="border: 1px solid #ccc;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editorId="编辑器二号" />
								<Editor style="height: 350px;" :editorId="编辑器二号" :defaultConfig="editorConfig"
									:defaultHtml="编辑文章表单.文章内容" />
							</div>
						</el-form-item>

					</el-form>
				</div>
				<div class="text-c">
					<el-button type="primary" @click="提交编辑文章表单(编辑文章表单编号)" :loading="编辑文章中">提交</el-button>
				</div>

			</div>
		</el-dialog>

	</div>

</template>



<script setup>
	import {
		ref,
		reactive,
		toRefs,
		onMounted,
		onBeforeUnmount
	} from 'vue';

	import {
		ElMessage,
		ElForm,
	} from 'element-plus';

	import {
		Search
	} from '@element-plus/icons-vue';

	import {
		Editor,
		Toolbar,
		getEditor,
		removeEditor
	} from '@wangeditor/editor-for-vue'

	// import cloneDeep from 'lodash.clonedeep'

	import {
		get_host,
		check_token,
		article_list,
		article_type,
		get_article_info,
		add_article,
		edit_article,
		exit_login
	} from '@/api';

	import Prism from "prismjs";

	const 发布文章表单编号 = ref(ElForm);
	const 编辑文章表单编号 = ref(ElForm);

	let 服务器网址 = ref('');
	let 当前年份 = ref(new Date().getFullYear());
	let 显示菜单 = ref(true);
	let 搜索关键词 = ref('');
	let 显示发布文章 = ref(false);
	let 发布文章中 = ref(false);
	let 显示编辑文章 = ref(false);
	let 编辑文章中 = ref(false);
	let 编辑器一号 = ref(`w-e-${Math.random().toString().slice(-5)}`);
	let 编辑器二号 = ref(`w-e-${Math.random().toString().slice(-5)}`);

	const 数组对象 = reactive({
		用户信息: null,
		文章分类: [],
		文章列表: [],
		文章详情: {},
		文章类型: [],
		发布文章表单: {
			文章标题: '',
			文章分类: '',
			文章摘要: '',
			文章内容: '',
		},
		发布文章表单规则: {
			文章标题: [{
				required: true,
				message: '文章标题不能为空',
				trigger: ['blur', 'change']
			}],
			文章分类: [{
				required: true,
				message: '文章分类不能为空',
				trigger: ['blur', 'change']
			}],
			文章摘要: [{
				required: true,
				message: '文章摘要不能为空',
				trigger: ['blur', 'change']
			}],
			文章内容: [{
				required: true,
				message: '文章内容不能为空',
				trigger: ['blur', 'change']
			}],
		},
		编辑文章表单: {
			文章标题: '',
			文章分类: '',
			文章摘要: '',
			文章内容: '',
		},
		编辑文章表单规则: {
			文章标题: [{
				required: true,
				message: '文章标题不能为空',
				trigger: ['blur', 'change']
			}],
			文章分类: [{
				required: true,
				message: '文章分类不能为空',
				trigger: ['blur', 'change']
			}],
			文章摘要: [{
				required: true,
				message: '文章摘要不能为空',
				trigger: ['blur', 'change']
			}],
			文章内容: [{
				required: true,
				message: '文章内容不能为空',
				trigger: ['blur', 'change']
			}],
		},
		editorConfig: {
			placeholder: '请输入内容...'
		},

	});

	const {
		用户信息,
		文章分类,
		文章列表,
		文章详情,
		发布文章表单,
		发布文章表单规则,
		编辑文章表单,
		编辑文章表单规则,
		editorConfig
	} = toRefs(数组对象);

	onMounted(() => {
		获取服务器地址();
		自动登录();
		获取文章分类();
		获取文章列表();
	});

	const 获取服务器地址 = () => {
		get_host().then(res => {
			服务器网址.value = res;
		});
	};

	const handleNodeClick = (e) => {
		console.log(e.type);
		if ('文章' == e.type) {
			获取文章详情(e.id);
		}
	};

	const 自动登录 = () => {
		check_token().then(res => {
			console.log(res);
			if (1 === res.code) {
				数组对象.用户信息 = res.data;
				ElMessage.success('自动登录成功');
			} else {
				ElMessage.error('自动登录失败');
			}
		});
	};

	const 退出 = () => {
		let 登录口令 = localStorage.getItem("登录口令");
		exit_login({
			登录口令: 登录口令
		}).then(res => {
			console.log(res);
		});
		localStorage.removeItem("登录口令");
		数组对象.用户信息 = null;
		ElMessage.success('退出成功');
	};

	const 打开登录 = () => {
		window.location.href = "login.html";
	};

	const 打开管理 = () => {
		window.location.href = "index.html";
	};

	const 编辑文章 = (id) => {
		get_article_info({
			id: id
		}).then(res => {
			console.log(res);
			if (1 === res.code) {
				数组对象.编辑文章表单 = res.data;
				显示编辑文章.value = true;
			} else {
				ElMessage.error(res.msg);
			}
		});
	};

	const 提交编辑文章表单 = (formEl) => {
		if (!formEl) {
			ElMessage.error('找不到表单');
			return;
		}
		编辑文章中.value = true;
		const editor = getEditor(编辑器二号.value);
		let text = editor.getText();
		let html = editor.getHtml();
		console.log(text);
		console.log(html);
		if (text) {
			数组对象.编辑文章表单.文章内容 = html;
			let form = 数组对象.编辑文章表单;
			formEl.validate((valid) => {
				console.log(valid);
				if (valid) {
					console.log(form);
					edit_article(form).then(res => {
						console.log(res);
						if (1 === res.code) {
							编辑文章中.value = false;
							ElMessage.success(res.msg);
							显示编辑文章.value = false;
							获取文章列表();
							获取文章详情(res.data);
						} else if (3 === res.code) {
							编辑文章中.value = false;
							ElMessage.warning(res.msg);
						} else {
							编辑文章中.value = false;
							ElMessage.error(res.msg);
						}
					});
				} else {
					ElMessage.error('编辑文章表单验证错误');
					编辑文章中.value = false;
					return false;
				}
			})
		} else {
			ElMessage.error('请输入内容');
			编辑文章中.value = false;
			return;
		}
	};

	const 发布文章 = () => {
		console.log('发布文章');
		数组对象.发布文章表单 = {
			文章标题: '',
			文章分类: '',
			文章摘要: '',
			文章内容: '',
		};
		显示发布文章.value = true;
	};

	const 提交发布文章表单 = (formEl) => {
		if (!formEl) {
			ElMessage.error('找不到表单');
			return;
		}
		发布文章中.value = true;
		const editor = getEditor(编辑器一号.value);
		let text = editor.getText();
		let html = editor.getHtml();
		console.log(text);
		console.log(html);
		if (text) {
			数组对象.发布文章表单.文章内容 = html;
			let form = 数组对象.发布文章表单;
			formEl.validate((valid) => {
				console.log(valid);
				if (valid) {
					console.log(form);
					add_article(form).then(res => {
						console.log(res);
						if (1 === res.code) {
							发布文章中.value = false;
							ElMessage.success(res.msg);
							显示发布文章.value = false;
							获取文章列表();
							获取文章详情(res.data);
						} else if (3 === res.code) {
							发布文章中.value = false;
							ElMessage.warning(res.msg);
						} else {
							发布文章中.value = false;
							ElMessage.error(res.msg);
						}
					});
				} else {
					ElMessage.error('发布文章表单验证错误');
					发布文章中.value = false;
					return false;
				}
			})
		} else {
			ElMessage.error('请输入内容');
			发布文章中.value = false;
			return;
		}
	};

	const 获取文章分类 = () => {
		article_type().then(res => {
			console.log(res);
			if (1 === res.code) {
				数组对象.文章分类 = res.data;
				ElMessage.success(res.msg);
			} else {
				ElMessage.error(res.msg);
			}
		});
	};

	const 获取文章列表 = () => {
		article_list({
			搜索关键词: 搜索关键词.value
		}).then(res => {
			console.log(res);
			if (1 === res.code) {
				数组对象.文章列表 = res.data;
				// 数组对象.文章类型 = res.data.type;
				// 数组对象.文章列表 = res.data.list;
				ElMessage.success(res.msg);
			} else {
				ElMessage.error(res.msg);
			}
		});
	};

	const 获取文章详情 = (id) => {
		get_article_info({
			id: id
		}).then(res => {
			console.log(res);
			if (1 === res.code) {
				数组对象.文章详情 = res.data;
				// this.$nextTick(() => {
				//   Prism.highlightAll();
				// });
				setTimeout(() => {
					Prism.highlightAll();
				}, 10);
				ElMessage.success(res.msg);
			} else {
				ElMessage.error(res.msg);
			}
		});
	};

	onBeforeUnmount(() => {
		const editor = getEditor(编辑器一号.value);
		const editor2 = getEditor(编辑器二号.value);
		if (editor != null) {
			// 【注意】组件销毁时，及时销毁编辑器
			editor.destroy()
			removeEditor(编辑器一号.value)
		}

		if (editor2 != null) {
			// 【注意】组件销毁时，及时销毁编辑器
			editor2.destroy()
			removeEditor(编辑器二号.value)
		}
	});
</script>

<style>
	body {
		margin: 0px;
	}

	a {
		text-decoration: none;
	}

	#app {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.首页标题 {
		color: #ffffff;
		text-align: center;
	}

	.首页标题:hover {
		background-color: #00aaff;
	}

	.文章标题 {
		font-size: 24px;
		font-weight: bold;
		text-align: center;
	}

	.文章描述 {
		background-color: #e6e2d3;
	}

	.aside {
		width: 300px;
		background-color: #e8e8e8;
		color: #333;
		text-align: center;
		border-right: 1px solid #d9d7d7;
	}



	.main {
		background-color: #fffae8;
		color: #333;
	}

	.main::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 10px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}

	.main::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #9e9c9c;
	}

	.main::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background: #ededed;
	}

	.el-footer {
		height: 30px;
		line-height: 30px;
		color: #FFF;
		background-color: #145ccd;
	}
</style>

<style scoped>
	.layout-container-demo .el-header {
		background-color: #145ccd;
		height: 50px;
		line-height: 50px;
		padding-left: 0;
		padding-right: 10px;
	}


	.layout-container-demo .el-menu {
		border-right: none;
	}

	.layout-container-demo .el-main {
		padding: 0;
	}

	.tree-menu {
		width: 100%;
		height: 100%;
		overflow: scroll;
	}

	.el-tree {
		display: inline-block;
		min-width: 100%;
		background-color: #e8e8e8;
		color: #1e1e1e;
		
		.el-tree-node.is-expanded.is-focusable{
			background-color: #28ef67 !important;
		}
		.el-tree-node.is-expanded.is-focusable:hover{
			background-color: #28ef67 !important;
		}
		.el-tree-node.is-focusable{
			background-color: #28ef67 !important;
		}
		.el-tree-node.is-focusable:hover{
			background-color: #28ef67 !important;
		}
		
		.el-tree-node.is-current.is-focusable{
			background-color: #28ef67 !important;
		}
		
	}


	
	
</style>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style>
	p,
	li,
	td,
	th,
	blockquote {
		white-space: pre-wrap;
		/* 显示空格 */
	}

	/* 表格 */
	table {
		border-collapse: collapse;
	}

	table th,
	table td {
		border: 1px solid #ccc;
		min-width: 50px;
		height: 20px;
		text-align: left;
	}

	table th {
		background-color: #f1f1f1;
		text-align: center
	}

	/* 代码块 */
	/* pre>code {
      display: block;
      border: 1px solid hsl(0, 0%, 91%);
      border-radius: 4px 4px;
      text-indent: 0;
      background-color: #fafafa;
      padding: 10px;
      font-size: 14px;
  } */

	/* 引用 */
	blockquote {
		display: block;
		border-left: 8px solid #d0e5f2;
		padding: 10px 10px;
		margin: 10px 0;
		background-color: #f1f1f1;
	}

	/* 列表 */
	ul,
	ol {
		margin: 10px 0 10px 20px;
	}

	/* 分割线 */
	hr {
		display: block;
		width: 90%;
		margin: 20px auto;
		border: 0;
		height: 1px;
		background-color: #ccc;
	}

	img {
		max-width: 100%;
	}
</style>
