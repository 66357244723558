import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '../../css/zidingyi-hui.css'
import 'prismjs/themes/prism.css'
import App from './php.vue'

const app = createApp(App)
app.use(ElementPlus)

app.mount('#app')